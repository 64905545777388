<template>
  <div class="h-12 mb-6 shrink-0 w-12">
    <cx-tooltip v-if="!isSidebarExpanded" placement="right" :distance="30">
      <button
        v-hubble="'toggle-button'"
        class="focus:outline-none focus:ring-red-700 hover:ring-red-700 ring-2 ring-transparent rounded-lg transition-shadow"
        aria-expanded="false"
        :aria-label="$t('expandSideNav')"
        @click="toggleSidebarExpandStatus"
      >
        <app-logo class="h-full hover:ring-red-700 w-full" />
      </button>

      <template #content>
        <div class="flex items-center justify-center space-x-2">
          <p class="text-white">{{ $auth.user.orgName }}</p>
          <subscriptions-wrapper v-slot="{ activeSubscriptions }" :subscriptions="subscriptions">
            <subscription-badge
              v-for="subscription in activeSubscriptions"
              :key="`${subscription.plan}_badge`"
              :plan="subscription.plan"
              class="self-start shrink"
            />
          </subscriptions-wrapper>
        </div>
      </template>
    </cx-tooltip>

    <button
      v-else
      class="bg-gray-600 cursor flex focus:outline-none focus:ring-red-700 h-full hover:ring-red-700 items-center justify-center ring-2 ring-transparent rounded-lg transition-shadow w-full"
      aria-expanded="true"
      :aria-label="$t('collapseSideNav')"
      @click="toggleSidebarExpandStatus"
    >
      <cx-icon name="close-fill" class="text-white" />
    </button>
  </div>
</template>

<script>
import AppLogo from '~/components/common/AppLogo';
import { CxTooltip, CxIcon } from '~/components/cx';
import SubscriptionBadge from '~/components/subscription/SubscriptionBadge';
import SubscriptionsWrapper from '~/components/subscription/SubscriptionsWrapper';

export default {
  name: 'AppSidebarLogo',

  hubble: 'app-sidebar-logo',

  components: {
    AppLogo,
    CxIcon,
    CxTooltip,
    SubscriptionBadge,
    SubscriptionsWrapper,
  },

  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    subscriptions() {
      return this.$auth.user.subscriptions;
    },
  },

  methods: {
    toggleSidebarExpandStatus() {
      this.$emit('toggle-expand');
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "collapseSideNav": "collapse side navigation",
    "expandSideNav": "expand side navigation"
  }
}
</i18n>
